import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from './libera-80.png';
// import logo from './libra-dark-chrome.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        padding-top: 4px;
        h2 {
          margin-top: 14px;
          // padding-bottom: 0px;
          border: 1pxc solid white;
          line-height: 1;
         }
`
);

const LogoSignWrapper = styled(Box)(
  ({ theme }) => `
        width: 80px;
        transform: scale(.9);
        transition: ${theme.transitions.create(['transform'])};
        &:hover {
        transform: scale(1);
        }
`
);

function Logo() {
  return (
    <LogoWrapper to="/">
      {/* <LogoSignWrapper>
        <img src={logo} width="70" alt="LiberaCoin.com" />
      </LogoSignWrapper> */}
      {/* <h2>
        <span style={{ letterSpacing: '3px', fontStyle: 'italic' }}>
          Libera
        </span>
        <br />
        <span
          style={{ fontSize: '16px', letterSpacing: '1.2px', fontWeight: 100 }}
        >
          coin.com
        </span>
      </h2> */}

      <h2>
        <span
          style={{
            fontSize: '24px',
            lineHeight: 1.2,
            paddingBottom: '14px',
            // letterSpacing: '3px',
            // fontStyle: 'italic',
          }}
        >
          Libera Coin
        </span>
        <br />
        <span
          style={{
            fontSize: '16px',
            lineHeight: 1.8,
            letterSpacing: '1.4px',
            fontWeight: 100,
            paddingRight: '24px',
          }}
        >
          liberacoin.com
        </span>
      </h2>
    </LogoWrapper>
  );
}

export default Logo;
