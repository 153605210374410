import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ThemeSettings from 'src/components/app/ThemeSettings';

import Authenticated from 'src/components/user/Authenticated';
// import NotAuthenticated from 'src/components/account/NotAuthenticated';
import Sidebar from './Sidebar';
import Header from './Header';
// import Footer from 'src/components/app/Footer';

interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  () => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  return (
    <>
      <MainWrapper>
        <Header />
        <Sidebar />
        <MainContent>
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default AccentHeaderLayout;
