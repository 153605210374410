import { useRoutes } from 'react-router-dom';
import routes from './router';
// import { SnackbarProvider } from 'notistack';
// import AdapterDateFns from '@mui/lab/AdapterDateFns';
// import LocalizationProvider from '@mui/lab/LocalizationProvider';
import useScrollToTop from 'src/hooks/useScrollToTop';
import useAuth from 'src/hooks/useAuth';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import ReactGA from 'react-ga';
// const TRACKING_ID = 'UA-12341234-1'; // YOUR_OWN_TRACKING_ID
const TRACKING_ID = 'UA-213410092-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const App = () => {
  const content = useRoutes(routes);
  const auth = useAuth();
  useScrollToTop();

  return (
    <ThemeProvider>
      {/* <LocalizationProvider dateAdapter={AdapterDateFns}> */}
      {/* <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        > */}
      <CssBaseline />
      {/* {auth.isInitialized ? content : <>auth not initialized</>} */}
      {content}
      {/* </SnackbarProvider> */}
      {/* </LocalizationProvider> */}
    </ThemeProvider>
  );
};
export default App;
