import { Paper, ListItemButton, ListItemText } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';

function Login() {
  const { t }: { t: any } = useTranslation();

  return (
    <ButtonGroup variant="contained" aria-label="outlined primary button group">
      <Button component={Link} to="/user/login">
        LOGIN
      </Button>
      <Button component={Link} to="/user/register">
        SIGN UP
      </Button>
    </ButtonGroup>
  );
}

export default Login;
