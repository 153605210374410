import { FC, ReactNode, useState } from 'react';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';

interface AuthenticatedProps {
  children: ReactNode;
}

const NotAuthenticated: FC<AuthenticatedProps> = (props: { children: any }) => {
  const { children } = props;
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return <></>;
  }

  return <>{children}</>;
};

NotAuthenticated.propTypes = {
  children: PropTypes.node,
};

export default NotAuthenticated;
